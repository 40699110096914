.spinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.done {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.not {
  opacity: 0;
  transition: opacity 0.3s ease;
}