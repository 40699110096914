.tag {
    display: flex;
    margin: 0.2rem;
    padding: 0.1rem;
    border-radius: 25px;
    width: fit-content;
    height: fit-content;
}

.name {
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    font-weight: 300;
    font-size: 12px;
}

.dot {
    position: relative;
    margin-top: 3px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    box-shadow: 0 0 1px rgba(0, 0, 0, 1);
}