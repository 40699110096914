
.App {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.Page {
    position: relative;
    min-height: 100vh;
    width: 90vw;
    max-width: 920px;
    margin: auto;
    padding-bottom: 4rem;
}

.Footer {
position: absolute;
  bottom: 0;
  width: 100vh;
  height: 2.5rem;
}

.fadeIn {
  animation: 0.2s fadeIn forwards;
}

.fadeOut {
  animation: 0.2s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

a {
    color: rgb(255, 255, 255);
}