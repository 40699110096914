

.innernav {
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 920px;
}

.navlinks {
    padding: 1rem;
    margin: auto;
    justify-content: center;
}

.link {
    margin: 1.2rem;
    color: aliceblue;
    text-decoration: none;
    border-radius: 8px;
    transition: color 0.2s ease;
}

.link:hover {
    color: rgb(198, 205, 212);
}

.logo {
    display: flex;
    font-size: 2rem;
}

.isschedijk, .aron {
    width: 0px;
    overflow: hidden;
    font-size: 2rem;
    font-weight: 100;
    transition: width 0.5s ease-in-out;
}

@media (min-width: 1000px) {
    .logo:hover > .isschedijk {
        width: 155px;
    }
    
}


.logo:hover > .aron {
    width: 75px;
}


.icon {
    scale: 1.3;
    filter: invert(1);
    margin-left: 2rem;
    transition: filter 0.2s ease;
}

.icon:hover {
    filter: invert(0.8);
}