body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  color: rgb(255, 255, 255);

  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;

  background: rgb(176, 144, 113);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

code {
  font-family: 'Poppins', sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  overflow-y: scroll;
}

.pagetitle {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

body,
html {
  height: 100%;
}