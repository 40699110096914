.main {
  margin-top: 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-70%);
  display: flex;
}

@media (max-width: 768px) {
  .main {
    transform: translateY(-50%);
  }
}


.image {
  height: 22rem;
  width: 22rem;
  margin-left: 2rem;
  filter: grayscale(1);
  border: 6px dashed rgb(97, 97, 97);
  border-radius: 12px;
  transition: all 0.3s ease;

}

.image:hover {
  filter: grayscale(.5);
}

.head {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.name {
  color: rgb(97, 97, 97);
}

.projectbutton, .contactbutton {
  background-color: rgb(97, 97, 97);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  margin-right: 1rem;
  margin-top: 1rem;
  transition: all 0.3s ease;
  font-family: poppins;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.projectbutton:hover, .contactbutton:hover {
  background-color: rgb(0, 0, 0);
}

@media (max-width: 768px) {
  .main {
      flex-direction: column-reverse;
  }

  .image {
      margin-left: 0;
      margin-bottom: 5rem;
      object-fit: cover;
      max-width: 300px;
  }
}