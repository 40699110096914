.about {
    display: flex;
}



.bio {
    margin-right: 100px;
}

.bio > p {
    padding-bottom: 0.5rem;
}

.image {

    margin-left: auto;
    object-fit: cover;
    max-width: 300px;
}

@media (max-width: 768px) {
    .about {
        flex-direction: column-reverse;
    }

    .image {
        margin-left: 0;
        margin-bottom: 5rem;
        object-fit: cover;
        max-width: 300px;
    }
}